import { $ } from '@situation/setdesign.util';

$(() => {
    $('.video--hover-controls').hover(function toggleControls() {
        if (this.hasAttribute('controls')) {
            this.removeAttribute('controls');
        } else {
            this.setAttribute('controls', 'controls');
        }
    });
});
