import { $ } from '@situation/setdesign.util';

$(() => {
    const $floatingSections = $('section.content-section--floating-section');
    const adjustFloatingSections = () => {
        $floatingSections.each((_, elem) => {
            const sectionHeight = elem.getBoundingClientRect().height;
            $(elem).prev().css('padding-bottom', sectionHeight / 2);
            $(elem).next().css('padding-top', sectionHeight / 2);
        });
        window.requestAnimationFrame(adjustFloatingSections);
    };

    window.requestAnimationFrame(adjustFloatingSections);
});
