import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    /**
     * Uncomment to restore legend
     */
    // $on(TicketCalendar.VUE_RENDERED, () => {
    // const $accordionHeader = $('.tc-accordion__header');
    // $accordionHeader.each((_, elem) => {
    //     $(elem).prepend('<div class="tc-accordion__legend">'
    //         + '<span class="star">&#9733;</span> =  Best Availability &amp; Price</div>');
    // });
    // });

    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'forcoloredgirls-theme',
            'disable-default-analytics': true,

            'scroll-calendar': {
                instructionText: '<p>scroll to find a performance</p>'
                /* + '<p class="best-avail"><span class="star">★</span> ' */
                /* + '=  Best Availability &amp; Price</p>' */,
            },

            // set a different width to switch to desktop view
            /* 'breakpoint': 1025, */

            // use a parent container if components are separated
            /* 'vue-parent': $('.js-ticket-calendar-wrap').length ?
            '.js-ticket-calendar-wrap' :
            '.ticket-calendar', */

            // change the aspect ratio of calendar date cells
            /* 'fullcalendar': {
                aspectRatio: 1.1
            }, */

            // change the height of scroll wheel rows
            /* 'mobiscroll': {
                height: 35
            }, */

            // change properties of the tag highlighter
            /* 'tag-highlighter': {
                instructionText: '<p>Filter above to see best options!</p>'
            }, */

            // add a custom arrow svg icon with class called .tc-accordion__toggle-custom
            'accordion-month-header': {
                arrowSvgName: 'icon-down',
            },

            // modify the event urls
            /* 'filter-url': function(url) {
                var deferred = $.Deferred();
                (function() {
                    //do something asynchronously with the url
                    deferred.resolve(url);
                })();
                return deferred.promise();
            }, */

            // change properties of the selected event component
            'selected-event': {
                buttonClass: 'button button--default',
                eventFormat(event) {
                    return `${event.format('ddd, MMM D')
                    } at <span class="tc-selected-event__time">${
                        event.format(event.smartTimeFormat(''))
                    }</span><span class="tc-selected-event__meridiem">${
                        event.format('A')}</span>`;
                },
            },
            fullcalendar: {
                timeFormat: 'h:mma',
            },
        });
    });
});
