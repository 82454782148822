import { $, addAction } from '@situation/setdesign.util';

addAction('lazyImageLoaded', (img) => {
    const $imgElem = $(img);
    const $vcImage = $imgElem.parents('.vc-single-image');

    if ($vcImage.hasClass('fixed-position')) {
        const $fixedPositionWrapper = $('<div class="fixed-position__wrapper"></div>');
        $fixedPositionWrapper.append($imgElem.clone());
        $imgElem.css('visibility', 'hidden').before($fixedPositionWrapper);

        (($i, $w) => {
            const updatePosition = () => {
                const offset = $i.offset();
                $w.css({
                    top: offset.top,
                    left: offset.left,
                    width: $i.width(),
                    height: $i.height(),
                });
                window.requestAnimationFrame(updatePosition);
            };
            window.requestAnimationFrame(updatePosition);
        })($imgElem, $fixedPositionWrapper);
    }
});
